<template>
  <component :is="theme === 'dark' ? 'LogoDark' : 'LogoLight'" :style="`height: ${$vuetify.breakpoint.mdAndUp ? height : height - 20 }px;` + ``"/>
</template>
<script>
export default {
  props: {
    theme: String,
    height: [ Number, String ]
  },
  components: {
    LogoDark: () => import('@/../public/White.svg'),
    LogoLight: () => import('@/../public/Color.svg')
  }
}
</script>

<style lang="scss">
</style>
